import { useState } from 'react';
import { useCustomer } from 'hooks';
import fetchJson from 'lib/utils/fetchJson';
import { trackInWindow, trackEvents } from 'hooks/tracking/track';
import validatePassword from 'lib/validatePassword';

export const useRegisterCustomer = ({ currency }) => {
  const [state, setState] = useState({
    response: null,
    loading: false,
    error: null,
  });

  const { mutateCustomer } = useCustomer({
    redirectTo: `/account`,
    redirectIfFound: true,
  });

  const registerCustomer = async ({ email, password, birthDay, gender, acceptsMarketing }) => {
    setState({ response: null, loading: true, error: null });

    const passwordError = validatePassword(password);
    if (passwordError) {
      setState({ response: null, loading: false, error: passwordError });
    } else {
      try {
        await mutateCustomer(
          fetchJson('/api/shopify/account/register', {
            method: 'POST',
            headers: { currency },
            body: JSON.stringify({
              email,
              password,
              birthDay,
              gender,
              acceptsMarketing,
            }),
          })
        );
        trackInWindow({
          event: trackEvents.createAccount,
        });
        setState({
          response: true,
          loading: false,
          error: null,
        });
      } catch (error) {
        setState({
          response: null,
          loading: false,
          error: error?.message !== '' ? error.message : true,
        });
      }
    }
  };

  return [registerCustomer, state];
};

export default useRegisterCustomer;
