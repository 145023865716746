const { default: config } = require('./config');

const { seperator, storeLocales: storeLocalesObject, defaultLocale, defaultStoreLocale } = config;

const slugify = (store, locale) => [store, locale].join(seperator).toLocaleLowerCase();

const storeLocales = Object.entries(storeLocalesObject).reduce(
  (slugs, [store, locales]) => [...slugs, ...locales.map(locale => slugify(store, locale))],
  []
);
const locales = Array.from(new Set(Object.values(storeLocalesObject).flat()));

const storeLabels = {
  eu: 'EUR €',
  uk: 'GBP £',
  us: 'USD $',
};

const localeLabels = {
  nl: 'DUTCH',
  en: 'ENGLISH',
  de: 'GERMAN',
};

/**
 * Export compatible with import & require
 */
Object.defineProperty(exports, '__esModule', {
  value: true,
});

exports.stores = Object.keys(storeLocalesObject);
exports.locales = locales;
exports.defaultStoreLocale = defaultStoreLocale;
exports.defaultLocale = defaultLocale;
exports.storeLocales = storeLocalesObject;
exports.storeLabels = storeLabels;
exports.localeLabels = localeLabels;

exports.default = storeLocales;
