import { useState } from 'react';
import { useCustomer } from 'hooks';
import fetchJson from 'lib/utils/fetchJson';
import { PREFIX as accountPath } from 'components/pages/account/dashboard/slugs';
import { trackInWindow, trackEvents } from 'hooks/tracking/track';
import { useRouter } from 'next/router';
import stripShopifyGid from 'lib/utils/stripShopifyGid';

export const useLoginCustomer = ({ currency }) => {
  const {
    query: { return_url: returnUrl },
  } = useRouter();

  const [state, setState] = useState({
    response: null,
    loading: false,
    error: null,
  });

  // if customer is already logged in, redirect to account
  const { mutateCustomer } = useCustomer({
    redirectTo: `${returnUrl || accountPath}`,
    redirectIfFound: true,
  });

  const loginCustomer = async ({ email, password, multipassToken, isMultipass = false }) => {
    // For non-multipass login, email and password are required
    if (!isMultipass && (!email || !password)) {
      setState({
        response: null,
        loading: false,
        error: 'Email or password is missing',
      });
    }

    // For multipass login, multipassToken is required
    if (isMultipass && !multipassToken) {
      setState({
        response: null,
        loading: false,
        error: 'OAuth Login Failed',
      });
    }

    setState({ response: null, loading: true, error: null });

    try {
      const response = await fetchJson('/api/shopify/account/login', {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Content-Type',
          'Content-Type': 'application/json',
          currency,
        },
        body: JSON.stringify({
          email,
          password,
          isMultipass,
          multipassToken,
        }),
      });
      await mutateCustomer(response);
      trackInWindow({
        event: trackEvents.login,
        userId: response?.id ? stripShopifyGid(response?.id) : null,
      });
      setState({
        response: true,
        loading: false,
        error: null,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setState({
        response: null,
        loading: false,
        error: error?.data?.error || 'Login email or password is incorrect',
      });
    }
  };

  return [loginCustomer, state];
};

export default useLoginCustomer;
