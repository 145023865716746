import Head from 'next/head';
import { useRouter } from 'next/router';
import { defaultLocale } from 'lib/storeLocales/storeLocales';

const baseUrl = process.env.NEXT_PUBLIC_SITEURL;
const siteName = process.env.NEXT_PUBLIC_SITENAME;

const GlobalStructuredData = () => {
  const { asPath, locale } = useRouter();
  const pathWithoutQueryOrAnchor = `${asPath.split('/').slice(0, -1).join('/')}/`;
  const localePath = locale && locale !== defaultLocale ? `/${locale}` : '';
  const url = `${baseUrl}${localePath}${pathWithoutQueryOrAnchor}`;

  return (
    <Head>
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            name: siteName,
            url,
            '@id': '#WebSite',
            inLanguage: locale,
            potentialAction: {
              '@type': 'SearchAction',
              target: `${baseUrl}${localePath}/search?&q={query}`,
              query: 'required',
            },
            publisher: {
              '@type': 'Organization',
              '@id': `${baseUrl}#Organization`,
              name: siteName,
              url: baseUrl,
              sameAs: [
                'https://www.facebook.com/denhamthejeanmaker',
                'https://www.instagram.com/denham/',
              ],
              logo: {
                '@type': 'ImageObject',
                url: 'https://cdn.shopify.com/s/files/1/0593/4668/2015/files/DENHAM-LOGOTYPE.png?v=1653991915&width=132&height=18',
                width: 132,
                height: 18,
              },
            },
          }),
        }}
      />
    </Head>
  );
};

export default GlobalStructuredData;
